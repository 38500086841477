import { Component, OnInit, Output, EventEmitter, Input, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  @Output() scrollTo = new EventEmitter<String>();
  @Input() displayBackground = false;

  isCollapsed = false;

  constructor() { }

  ngOnInit() {

  }

  toggleNavbar() {
    this.isCollapsed = !this.isCollapsed;
  }

  scrollToElement(url) {
    this.isCollapsed = false;
    this.scrollTo.emit(url);
  }
}
