import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpResponseBase } from '@angular/common/http';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  email: string;
  name: string;
  message: string;

  valid_email = true;
  valid_name = true;
  valid_message = true;

  success = false;
  sent = false;
  submitLoader = false;

  cloudFunctionURL = 'https://us-central1-oraclereef.cloudfunctions.net';

  constructor(private http: HttpClient) { }

  ngOnInit() {

  }

  emailChanged() {
    this.valid_email = true;
  }

  nameChanged() {
    this.valid_name = true;
  }

  messageChanged() {
    this.valid_message = true;
  }

  clearErrors() {
    this.valid_email = true;
    this.valid_message = true;
    this.valid_name = true;
  }

  submit() {
    let valid = true;
    if(this.name === "" || !this.name) {
      this.valid_name = false;
      valid = false;
    }
    if(!this.checkValidEmail()) {
      this.valid_email = false;
      valid = false;
    }
    if(this.message === "" || !this.message) {
      this.valid_message = false;
      valid = false;
    }
    if(!valid) {
      return;
    }
    this.clearErrors();
    this.sendEmail();
  }

  sendEmail() {
    this.submitLoader = true;
    let url = `${this.cloudFunctionURL}/httpEmail`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json').set('Access-Control-Allow-Origin', '*');

    const data = {
      fromEmail: this.email,
      toName: this.name,
      message: this.message
    }

    let options = {
      headers: headers
    }

    this.http.post(url, data, options).toPromise().then((res: HttpResponseBase) => {
      if(res.status === 200) {
        this.successStatus();
      } else {
        this.failureStatus();
      }
    }).catch((err) => {
      if (err.status === 200) {
        this.successStatus();
      } else {
        this.failureStatus();
      }
      console.log(err);
    })
  }

  successStatus() {
    this.sent = true;
    this.success = true;
    this.submitLoader = false;
  }

  failureStatus() {
    this.sent = true;
    this.success = false;
    this.submitLoader = false;
  }

  checkValidEmail() {
    const expression = /(?!.*\.{2})^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([ \t]*\r\n)?[ \t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([ \t]*\r\n)?[ \t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;
    return expression.test(String(this.email).toLowerCase())
  }
}
