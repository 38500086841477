import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-team',
  templateUrl: './team.component.html',
  styleUrls: ['./team.component.scss']
})
export class TeamComponent implements OnInit {

  constructor() { }

  ngOnInit() {

  }

  urlClicked(url: string) {
    window.open(url, "_blank");
  }

  updateSource(event, url) {
    setTimeout(() => {
      event.target.src = url;
    }, 500);
  }
}
