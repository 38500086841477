import { Component, OnInit } from '@angular/core';
import { WorkService } from 'src/app/services/work.service';

@Component({
  selector: 'app-work',
  templateUrl: './work.component.html',
  styleUrls: ['./work.component.scss']
})
export class WorkComponent implements OnInit {

  workList;
  filtersLoaded: Promise<boolean>;

  constructor(private workService: WorkService) {
    this.workService.getWork().subscribe((res) => {
      this.workList = res;
      this.filtersLoaded = Promise.resolve(true);
    })
  }

  ngOnInit() {

  }

}
