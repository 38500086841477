import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { WavesComponent } from './components/waves/waves.component';
import { TypingComponent } from './components/typing/typing.component';
import { ServicesComponent } from './components/services/services.component';
import { LoadingScreenComponent } from './pages/loading-screen/loading-screen.component';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { LoadingScreenInterceptor } from './loading.interceptor';
import { TeamComponent } from './components/team/team.component';
import { WorkComponent } from './components/work/work.component';
import { ContactComponent } from './components/contact/contact.component';
import { FormsModule } from '@angular/forms';
import { FooterComponent } from './components/footer/footer.component';
import { HomeComponent } from './pages/home/home.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { CollapseModule } from 'ngx-bootstrap';
import { OfferComponent } from './components/offer/offer.component';


@NgModule({
  declarations: [
    AppComponent,
    WavesComponent,
    TypingComponent,
    ServicesComponent,
    LoadingScreenComponent,
    TeamComponent,
    WorkComponent,
    ContactComponent,
    FooterComponent,
    HomeComponent,
    NavbarComponent,
    OfferComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    CollapseModule.forRoot()
   ],
  providers: [{
   provide: HTTP_INTERCEPTORS,
   useClass: LoadingScreenInterceptor,
   multi: true
  }, HttpClientModule],

  bootstrap: [AppComponent]
})
export class AppModule { }
