import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpResponseBase } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class WorkService {

  cloudFunctionURL = 'https://us-central1-oraclereef.cloudfunctions.net';

  constructor(private http: HttpClient) {

  }

  getWork() {
    let url = `${this.cloudFunctionURL}/getWork`;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json').set('Access-Control-Allow-Origin', '*');

    let options = {
      headers: headers
    }
    return this.http.get(url, options);
  }

}
