import { Component } from '@angular/core';
import { WorkService } from './services/work.service';

declare var particlesJS: any;
declare var AOS: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  loading = true;

  constructor(private workService: WorkService) {
    particlesJS.load('particles-js', '../assets/particles.json', function() {
      console.log('callback - particles.js config loaded');
    });
    AOS.init();
    setTimeout(() => {
      this.loading = false;
    }, 1500)
    this.workService.getWork();
  }

}
