import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-typing',
  templateUrl: './typing.component.html',
  styleUrls: ['./typing.component.scss']
})
export class TypingComponent implements OnInit {
  
  content: String[] = ["DEVELOPMENT", "DESIGN", "BRANDING", "CREATIVE"];
  asdf = [];
  partIdx = 0;
  partIdx_INDEX = 0;
  interval_value = null;
  element: HTMLElement = null;
  cursor: HTMLElement = null;

  constructor() { 

  }

  ngOnInit() {
    this.element = document.getElementById("text");
    this.interval_value = setInterval(() => this.typeIt(), 100);
  }

  typeIt() {
    let text = this.content[this.partIdx].substring(0, this.partIdx_INDEX + 1);
    document.getElementById("text").innerHTML = text;
    this.partIdx_INDEX++;
  
    // If full sentence has been displayed then start to delete the sentence after some time
    if(text === this.content[this.partIdx]) {
      clearInterval(this.interval_value);
      setTimeout(() => {
        this.interval_value = setInterval(() => {
          this.deleteMe();
        }, 100);
      }, 1000);
    }
  }

  deleteMe() {
    // Get substring with 1 characater deleted
    let text = this.content[this.partIdx].substring(0, this.partIdx_INDEX - 1);
    document.getElementById("text").innerHTML = text;
    this.partIdx_INDEX--;
  
    // If sentence has been deleted then start to display the next sentence
    if(text === '') {
      clearInterval(this.interval_value);
      if(this.partIdx == (this.content.length - 1)) {
        this.partIdx = 0;
      } else {
        this.partIdx++;
      }
      
      this.partIdx_INDEX = 0;
  
      // Start to display the next sentence after some time
      setTimeout(() => {
        // document.getElementById("cursor").style.display = 'inline-block';
        this.interval_value = setInterval(() => this.typeIt(), 100);
      }, 200);
    }
  }
}