import { Component, OnInit, Input, HostListener } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  @Input() loading = false;
  displayBackground = false;

  @HostListener("window:scroll", []) 
  onWindowScroll() {
    let number = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    if(number > 900) {
      this.displayBackground = true;
    } else {
      this.displayBackground = false;
    }
  }

  constructor() {}

  ngOnInit() {

  }

  scrollToElement(url): void {
    const $element = document.getElementById(url);
    console.log($element)
    $element.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
  }


}
